<template>
  <v-simple-table v-if="!loading" class="info_table">
    <template v-slot:default>
      <tbody>
      <tr>
        <td class="detail_column">
          Číslo výkupu
        </td>
        <td class="detail_column">
          {{ responseData.number }}
        </td>
      </tr>
      <tr>
        <td class="detail_column">
          Celková cena
        </td>
        <td class="detail_column">
          {{ formatPrice(responseData.total_price) }} Kč
        </td>
      </tr>
      <tr>
        <td class="detail_column">
          Stav výkupu
        </td>
        <td class="detail_column">
          {{ responseData.type_status }}
        </td>
      </tr>
      <tr>
        <td class="detail_column">
          Datum dokončení
        </td>
        <td class="detail_column">
          {{ formatDate(responseData.finished_date) }}
        </td>
      </tr>
      <tr>
        <td class="detail_column">Náhled Smlouvy</td>
        <td class="detail_column"><a :href="contractUrl"><v-icon>mdi-file</v-icon></a>
        </td>
      </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<style>
.detail_column{
  text-align: left;
  font-weight: bold;
}
</style>
<script>

import {fetchBuyoutBasicInfo} from "./api";
import {formattedDate, formattedPrice} from "@/utils/formatters";
import {URLS} from "../../api/config";
import {BASE_API_URL} from "../../api/axios-base";

export default {
  name: 'infoCard',
  props: {
    buyoutId: {type: String, required: true}
  },
  data() {
    return {
      responseData: null,
      loading: true
    }
  },
  async mounted() {
    await fetchBuyoutBasicInfo(this.buyoutId)
        .then(response => {
          this.responseData = response.data
          this.loading = false
        })
  },
  computed: {
    contractUrl() {
      return `${BASE_API_URL}/${URLS.contractPreview(this.buyoutId)}`
    }
  },

  methods: {
    formatDate(value){
      return formattedDate(value)
    },
    formatPrice(value){
      return formattedPrice(value)
    }
  }
}
</script>