import {axiosBase} from "@/api/axios-base";
import {URLS} from "@/api/config";
import {AxiosResponse} from "axios";
import {ITypeStatus} from "@/components/buyouts/config";

export const fetchAttrNames = async (): Promise<AxiosResponse<Array<ITypeStatus>>> => {
    return await axiosBase.get(URLS.fetchAttrNames())
}

export const fetchSellPrices = async (): Promise<AxiosResponse<Array<ITypeStatus>>> => {
    return await axiosBase.get(URLS.fetchSellPrices())
}

export const fetchBuyPrices = async (year: number, month: number): Promise<AxiosResponse<Array<ITypeStatus>>> => {
    return await axiosBase.get(URLS.fetchBuyPrices(year, month))
}

export const fetchProductOverview = async (code: string): Promise<AxiosResponse<Array<ITypeStatus>>> => {
    return await axiosBase.get(URLS.fetchProductOverview(code))
}
export const getBatchItemXmlData = async (code: string): Promise<AxiosResponse<Array<ITypeStatus>>> => {
    return await axiosBase.get(URLS.getBatchItemXmlData(code))
}