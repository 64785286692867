import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home/App'
import Login from '../views/auth/login/App'
import BuyoutsList from '../views/buyoutsList/App'
import TimeOut from '../views/auth/timeOut/App'
import Admin from '../views/admin/App'
import NewBuyout from '../views/newBuyout/App'
import BuyoutDetail from '../views/buyoutDetail/App'
import BuyoutProcess from '../views/buyoutProcess/App'
import GoldSmith from '../views/goldsmith/App'
import GoldSmithDetail from '../views/goldsmithDetail/App'
import ProductOverview from '../views/product/App'
import Account from '../views/account/App'
// import NotFound from '../views/404/App'
import store from "../store";
import BuyoutBatchDetail from "../components/buyoutBatch/BuyoutBatchDetail.vue";
import BatchSendToBoss from "../components/batch/BatchSendToBoss.vue";
import BatchList from "../components/batch/BatchList.vue";
import BatchReview from "../components/batch/BatchReview.vue";

Vue.use(VueRouter)

const routes = [
    // PAGE ROUTES
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            loginRequired: true,
            sectionTitle: 'Nový výkup'
        },
    },
    {
        path: '/buyouts/list',
        name: 'BuyoutsList',
        component: BuyoutsList,
        meta: {
            loginRequired: true,
            sectionTitle: 'Seznam výkupů'
        }
    },
    {
        path: '/batch/list',
        name: 'BatchList',
        component: BatchList,
        meta: {
            loginRequired: true,
            sectionTitle: 'Zpracování - várky'
        }
    },
    {
        path: '/buyouts/process',
        name: 'BuyoutProcess',
        component: BuyoutProcess,
        meta: {
            loginRequired: true,
            sectionTitle: 'Zpracování - výkupy'
        }
    },
    {
        path: '/buyouts/new/:id',
        name: 'NewBuyout',
        component: NewBuyout,
        meta: {
            loginRequired: true,
            sectionTitle: 'Nový výkup'
        }
    },
    {
        path: '/buyouts/:id',
        name: 'DetailBuyout',
        component: BuyoutDetail,
        meta: {
            loginRequired: true,
            sectionTitle: 'Detail výkupu'
        }
    },
    {
        path: '/product/overview/:code?',
        name: 'ProductOverview',
        component: ProductOverview,
        meta: {
            loginRequired: true,
            sectionTitle: 'Přehled produktu'
        }
    },

    {
        path: '/goldsmith',
        name: 'Goldsmith',
        component: GoldSmith,
        meta: {
            loginRequired: true,
            sectionTitle: 'Zlatník'
        }
    },
    {
        path: '/goldsmith/detail/:id',
        name: 'GoldsmithDetail',
        component: GoldSmithDetail,
        meta: {
            loginRequired: true,
            sectionTitle: 'Zlatník - zpracovat výkup'
        }

    },
    {
        path: '/account',
        name: 'Account',
        component: Account,
        meta: {
            loginRequired: true,
            sectionTitle: 'Účet - změna hesla'
        }
    },
    // ADMIN ROUTES
    {
        path: '/admin-fe',
        name: 'Admin',
        component: Admin,
        meta: {
            loginRequired: true,
            adminRequired: true
        }
    },

    // AUTH ROUTES
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            unauthorizedRequired: true,
            allowAnonymous: true
        },
        props: true
    },
    {
        path: '/time-out',
        name: 'TimeOut',
        component: TimeOut,
        meta: {
            allowAnonymous: true,
            sectionTitle: 'Timeout...'
        }
    },
    {
        path: '/batch/detail/:id',
        name: 'BuyoutBatchDetail',
        component: BuyoutBatchDetail,
        meta: {
            loginRequired: true
        }
    },
    {
        path: '/batch/review/:id/:state/',
        name: 'BatchReview',
        component: BatchReview,
        props: true,
        meta: {
            loginRequired: true,
            sectionTitle: 'Zpracovat várku'
        }
    },
    {
        path: '/batch/boss/:id',
        name: 'BatchSendToBoss',
        component: BatchSendToBoss,
        props: true,
        meta: {
            loginRequired: true,
            sectionTitle: 'Založit produkt'
        }
    },

    // ERROR ROUTES
    // {
    //     path: '*',
    //     name: '404',
    //     component: NotFound,
    //     meta: {
    //         allowAnonymous: true
    //     }
    // }
]


const router = new VueRouter({
    routes
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.loginRequired)) {
        if (!store.getters.isLoggedIn) {
            next({name: 'Login'})
        } else {
            next()
        }
    } else if (to.matched.some(record => record.meta.unauthorizedRequired)) {
        if (store.getters.isLoggedIn) {
            next({name: 'Home'})
        } else {
            next()
        }
    } else {
        next()
    }
})

export default router
