<template>
  <v-dialog
      v-model="dialog"
      persistent
      max-width="1200"
  >

    <template v-slot:activator="{on, attrs }">
      <v-btn icon small class="aBtn--primaryInv">
        <v-icon
            v-bind="attrs"
            v-on="on"
        >
          mdi-plus
        </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-row justify="end">
        <v-col cols="auto">
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-container v-if="loading">
        <v-skeleton-loader type="list-item@18"></v-skeleton-loader>
      </v-container>

      <v-container v-else>
        <v-card-text class="pa-2">
          <v-form @submit.prevent="confirmSave" ref="attrForm" v-model="valid">
            <div class="app__grid app__grid--quaters ga-6 justify-content-between">
              <div v-for="(group, g, index) in attrNames" :key="g">
                <div class="text-subtitle-2 font-weight-bold px-3">
                  {{ attrTitles[index]?.title }}
                </div>
                <v-divider class="customerForm__divider mb-4"/>
                <div>
                  <div v-for="(attribute, i) in group" :key="i">
                    <div v-if="attribute.attr_choice" style="padding: 5px 0;">
                      <div v-if="attribute.attr_choice.length">
                        <v-select
                            :label="attribute.required_web ? attribute.name + '*' :attribute.name"
                            outlined
                            v-model="attribute.attrChoice"
                            :items="attribute.attr_choice"
                            :item-text="(value) => value.value"
                            :item-value="(value) => value"
                            :multiple="attribute.multiple_choice"
                            clearable
                            persistent-placeholder
                            :rules="attribute.required_web ? [rules.required] : []"
                            validate-on-blur
                        />

                      </div>
                      <div v-else-if="attribute.value_type==='str'">
                        <v-text-field
                            outlined
                            :label="attribute.required_web ? attribute.name + '*' :attribute.name"
                            v-model="attribute.attrValue"
                            :min="0"
                            persistent-placeholder
                            :rules="attribute.required_web ? [rules.required] : []"
                            validate-on-blur
                        >
                        </v-text-field>
                      </div>
                      <div v-else-if="attribute.value_type==='float'">
                        <v-text-field
                            outlined
                            :label="attribute.required_web ? attribute.name + '*' :attribute.name"
                            v-model="attribute.attrValue"
                            :min="0"
                            persistent-placeholder
                            :rules="[rules.float, ...(attribute.required_web ? [rules.required] : [])]"
                            validate-on-blur
                        >
                        </v-text-field>

                      </div>
                      <div v-else-if="attribute.value_type==='bool'">
                        <v-select
                            :label="attribute.required_web ? attribute.name + '*' :attribute.name"
                            outlined
                            v-model="attribute.attrValue"
                            :items="[{text: 'Ano' , value: 'true'}, {text: 'Ne' , value: 'false'},]"
                            :item-text="(value) => value.text"
                            :item-value="(value) => value.value"
                            clearable
                            persistent-placeholder
                            :rules="attribute.required_web ? [rules.required] : []"
                            validate-on-blur
                        />
                      </div>
                      <div v-else>
                        <v-text-field
                            outlined
                            :label="attribute.required_web ? attribute.name + '*' :attribute.name"
                            v-model="attribute.attrValue"
                            :min="0"
                            persistent-placeholder
                            :rules="attribute.required_web ? [rules.required] : []"
                            validate-on-blur
                        >
                        </v-text-field>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-form>
        </v-card-text>
        <v-card-actions class="d-flex flex-wrap mt-4">
          <v-btn class="aBtn--primaryInv flex-grow-1" @click="dialog=false">Zpět</v-btn>
          <v-btn class="primary flex-grow-1" @click="confirmSave" :loading="saving" :disabled="!valid">Uložit</v-btn>
        </v-card-actions>

      </v-container>

    </v-card>
  </v-dialog>
</template>

<script>
import rules from '@/utils/rules.js'
import {fetchAttrNames} from "@/components/zlaton/api";
import {saveAttributes} from "@/components/batch/api";
import {ACTION_TYPES as AT} from "@/store/action-types";

export default {
  props: {
    id: {required: true, type: String},
    msg: {required: false, type: String, default: ''},
    initAttributes: {required: false, type: Array},
  },
  data() {
    return {
      valid: false,
      loading: false,
      attrNames: [],
      attrTitles: [
        {title: 'Atributy 1'},
        {title: 'Centrální kámen'},
        {title: 'Ostatní kameny'},
        {title: 'Atributy 2'}
      ],
      dialog: false,
      saving: false,
      rules: {
        ...rules,
        float: value => {
          return value === '' || /^(\d+(\.\d*)?|\.\d+)$/.test(value) || "Hodnota musí být číslo";
        }
      },
    }
  },
  watch: {
    dialog: {
      handler() {
        this.getData()
      }
    }
  },
  methods: {
    confirmSave() {
      if (this.$refs.attrForm.validate()) {
        if (!this.valid) {
          this.focusOnFirstError();
        }
        let ungrupedAttrs = Object.values(this.attrNames).flat()
        this.saving = true;
        saveAttributes(this.id, ungrupedAttrs).then(() => {
          this.dialog = false
          this.$emit('save')
        }).finally(() => {
          this.saving = false
          this.$store.dispatch(AT.displayMessage, {
            errorMessage: 'Attributy byly úspěšně uloženy',
            many: false,
            isStringMessage: true
          })
        })
      }
    },
    async getData() {
      this.loading = true;
      await fetchAttrNames().then(response => {
        this.attrNames = Object.groupBy(response.data.map((i) => ({...this.getDefaultAttrValue(i), ...i})), ({group}) => group)
      })
      this.loading = false
      this.$nextTick(() => {
        this.$refs.attrForm.validate();
      })
    },
    getDefaultAttrValue(item) {
      const found = this.initAttributes.filter((i) => item.id === i.attribute_name_id);
      if (found.length) {
        const selected_values = found[0].attribute_choice_id.split(';');
        const choice = item.attr_choice.filter((i) => selected_values.includes(i.id));

        return {
          attrChoice: choice.length ? (item.multiple_choice ? choice : choice[0]) : null,
          attrValue: found[0].attribute_choice_value
        }
      }
      return {
        attrChoice: null,
        attrValue: "",
      }
    },
    focusOnFirstError() {
      const invalidInputs = this.$refs.attrForm.$children.filter(child => !child.isValid);
      if (invalidInputs.length > 0) {
        invalidInputs[0].$el.querySelector('input').focus();
      }
    }
  }
}

</script>