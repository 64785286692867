<template>
  <v-card>
    <v-card-text>
      <v-text-field
          prepend-inner-icon="mdi-magnify"
          label="Vyhledat..."
          outlined
          v-model="search"
          @keydown.enter="handleSearch"
          @input="handleChange"
      >
        <template #append>
          <v-tooltip top>
            <template #activator="{on}">
              <v-icon v-on="on">mdi-information-outline</v-icon>
            </template>
            <span>Pro vyhledávání stiskněte enter</span>
          </v-tooltip>
        </template>
      </v-text-field>

    </v-card-text>

    <v-container v-if="productOverview">
      <v-simple-table class="info_table">
          <tbody>
          <tr>
            <td class="detail_column">
              Kód produktu
            </td>
            <td class="detail_column">
              {{ productOverview.code }}
            </td>
          </tr>
          <tr>
            <td class="detail_column">
              Výkup
            </td>
            <td class="detail_column">
              <v-btn @click="showDetail(productOverview.buyout.id)">
                {{ productOverview.buyout.number }}
              </v-btn>
            </td>
          </tr>
          <tr>
            <td class="detail_column">
              Várka
            </td>
            <td class="detail_column">
              <v-btn @click="openExportToBoss(productOverview.batch.id)">
                {{ productOverview.batch.identifier }}
              </v-btn>
            </td>
          </tr>
          <tr>
            <td class="detail_column">
              Datum vytvoření
            </td>
            <td class="detail_column">
              {{ formattedDate(productOverview.buyout.x_created) }}
            </td>
          </tr>
          <tr>
            <td class="detail_column">Export XML</td>
            <td class="detail_column">
              <v-btn @click="downloadXml">Stáhnout</v-btn>
            </td>
          </tr>
          </tbody>
      </v-simple-table>
    </v-container>
    <v-card-text v-if="notFoundMsg" class="text-center"><p class="text-h4" style="color: red;">Kód {{ search }}
      nenalezen</p>
    </v-card-text>
  </v-card>

</template>

<script>
import {openRouteInNewTab} from "@/utils/navigation";
import {fetchProductOverview, getBatchItemXmlData} from "@/components/zlaton/api";
import {formattedDate} from "@/utils/formatters";

export default {
  name: 'ProductOverview',
  data() {
    return {
      productOverview: null,
      search: this.$route.params.code,
      notFoundMsg: false,

    }
  },
  methods: {
    formattedDate,
    handleSearch() {
      this.getProductOverview(this.search);
    },
    async downloadXml() {

      if (!this.productOverview) {
        return;
      }
      try {
        const response = await getBatchItemXmlData(this.productOverview.code);
        const url = window.URL.createObjectURL(new Blob([response.data], {type: "application/xml"}));
        const a = Object.assign(document.createElement("a"), {
          href: url,
          download: `${this.productOverview.code}.xml`
        });
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error("Error downloading XML:", error);
      }
    },
    async getProductOverview(code) {
      if (!code) {
        return;
      }
      try {
        const response = await fetchProductOverview(code);
        this.productOverview = response.data;
        this.$set(this, 'productOverview', response.data);
        this.notFoundMsg = false;
        if (this.$route.params.code !== code) {
          await this.$router.push({name: this.$route.name, params: {code}});
        }
      } catch (error) {
        console.log("Error fetching product overview:", error);
        this.$set(this, 'productOverview', null);
        this.notFoundMsg = true;
      }

    },
    showDetail(buyout_id) {
      openRouteInNewTab(this.$router, 'DetailBuyout', {id: buyout_id});
    },
    openExportToBoss(batch_id) {
      openRouteInNewTab(this.$router, 'BatchSendToBoss', {id: batch_id});
    },
    handleChange(newValue) {
      this.notFoundMsg = false;
      this.$set(this, 'productOverview', null);
    }
  },
  mounted() {
    this.getProductOverview(this.search);
  }

}
</script>