<template>
  <v-card>
    <v-card-title>
      <v-container class="ma-0">
        <v-row>
          <v-btn
              class="primary"
              min-width="250"
              @click="exportToBoss"
          >
            Poslat do BOSSe
          </v-btn>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  v-bind="attrs"
                  v-on="on"
                  class="aBtn--primaryInv ml-auto"
                  icon
                  @click="downloadExcel"
              >
                <v-icon>mdi-file-download</v-icon>
              </v-btn>
            </template>
            <span>Exportovat do excelu</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  v-bind="attrs"
                  v-on="on"
                  class="aBtn--primaryInv ml-4"
                  icon
                  @click="filters = !filters"
              >
                <v-icon>mdi-filter</v-icon>
              </v-btn>
            </template>
            <span>{{ filtersButtonText }}</span>
          </v-tooltip>
        </v-row>
      </v-container>
      <v-container v-if="filters">
        <v-row>
          <v-col
              cols="12"
              md="6"
          >
            <v-text-field
                prepend-inner-icon="mdi-magnify"
                label="Vyhledat..."
                outlined
                v-model="search"
                @keydown.enter="handleSearch"
            >
              <template #append>
                <v-tooltip top>
                  <template #activator="{on}">
                    <v-icon v-on="on">mdi-information-outline</v-icon>
                  </template>
                  <span>Pro vyhledávání stiskněte enter</span>
                </v-tooltip>
              </template>

            </v-text-field>
          </v-col>
          <v-col
              cols="12"
              md="1"
          />
          <v-col
              cols="12"
              md="5"
          >
            <v-select
                label="Stavy výkupu"
                outlined
                multiple
                v-model="selectedStatuses"
                :items="statuses"
                :item-value="(value) => value"
                :item-text="(value) => value.description"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
              cols="12"
              md="3"
          >
            <v-text-field
                outlined
                light
                :value="formatDate(dateFrom)"
                class="formInput--datePicker"
                label="Datum od..."
                :readonly="true"
                validate-on-blur
            >
              <template #append>
                <v-icon @click="dateFrom = '' ">mdi-minus-circle</v-icon>
                <DatePicker v-model="dateFrom"/>
              </template>
            </v-text-field>
          </v-col>
          <v-col
              cols="12"
              md="3"
          >
            <v-text-field
                outlined
                light
                :value="formatDate(dateTo)"
                class="formInput--datePicker"
                label="Datum do..."
                :readonly="true"
                validate-on-blur
            >
              <template #append>
                <v-icon @click="dateTo = '' ">mdi-minus-circle</v-icon>
                <DatePicker v-model="dateTo" :allowedDates="disableDatesBeforeDateFrom"
                            :showCurrent="dateFrom !== '' ? dateFrom:undefined"/>
              </template>
            </v-text-field>
          </v-col>
          <v-col
              cols="12"
              md="1"
          />
          <v-col
              cols="12"
              md="5"
          >
            <v-select
                label="Prodejny"
                outlined
                multiple
                v-model="selectedShops"
                :items="shops"
                :item-value="(value) => value"
                :item-text="(value) => value.description"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col
              cols="12"
              md="3"
          />
          <v-col
              cols="12"
              md="6">
            <v-btn class="aBtn--primaryInv" block @click="$router.go()">Obnovit výchozí</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-title>

    <v-data-table
        :headers="headers"
        :items="buyouts"
        :loading="loading"
        :options.sync="options"
        :server-items-length="dataLength"
        :items-per-page="defaultPageSize"
        class="elevation-2"
        :footer-props="{
          'items-per-page-text':'Počet výkupů na stránce',
          'items-per-page-options':itemsPerPageOptions,
          'rowsPerPage':defaultPageSize
        }"

    >
      <template #[`item.exported_to_BOSS`]="{ item }">
        <v-checkbox
            v-if="item.type_status.identifier === 'redeemed'"
            :disabled="item.exported_to_BOSS"
            :checked="item.exported_to_BOSS"
            style="margin-top:-3px ; "
            @change="exportToBossChange(item.id)"
        />
        <v-checkbox
            v-else
            :disabled="item.exported_to_BOSS"
            v-model="item.exported_to_BOSS"
            readonly
            style="margin-top:-3px ; "
        />
      </template>
      <template #[`item.number`]="{ item }">
        <a v-if="!goldsmith" @click="showDetail(item)">{{ item.number }}</a>
        <span v-else>{{ item.number }}</span>
      </template>
      <template #[`item.finished_date`]="{ item }">
        <span>{{ formatDate(item.finished_date) }}</span>
      </template>

      <template #[`item.total_price`]="{ item }">
        {{ formatPrice(item.total_price) }}
      </template>
      <template v-if="!goldsmith" #[`item.actions`]="{ item }">
        <div class="d-flex ga-4 ml-auto mw-mc">
          <v-btn
              v-if="item.type_status.identifier === 'new' || item.type_status.identifier === 'not_redeemed'"
              class="aBtn--primaryInv d-flex ml-auto"
              small
              icon
              @click="editBuyout(item)"
          >
            <v-icon small>
              mdi-pencil
            </v-icon>
          </v-btn>
          <v-btn
              v-else
              class="aBtn--primaryInv d-flex ml-auto"
              small
              icon
              @click="showDetail(item)"
          >
            <v-icon small>
              mdi-eye
            </v-icon>
          </v-btn>
          <DeletePopup
              v-if="item.type_status.identifier === 'new' || item.type_status.identifier === 'not_redeemed'"
              :delete-function="() => deleteItem(item)"
              msg="Opravdu si přejete smazat tento výkup?"
          />
        </div>
      </template>
      <template v-else #[`item.actions`]="{ item }">
        <v-btn small @click="processBuyoutByGoldsmith(item)" class="primary">
          Zpracovat výkup
        </v-btn>
      </template>
      <template #no-data>
        Podmínkám neodpovídají žádná data...
      </template>
      <template #loading>
        Načítání dat...
      </template>
    </v-data-table>
  </v-card>
</template>


<script>
import {
  fetchBuyouts,
  deleteBuyout,
  fetchAnother,
  fetchVisibleStatuses,
  fetchVisibleShops,
  exportBuyoutToBoss
} from "./api";
import DeletePopup from "../utils/DeletePopup";
import {formattedPrice, formattedDate} from "@/utils/formatters";
import DatePicker from "../utils/DatePicker";
import {goldsmithStatuses} from "./config";
import {URLS} from "../../api/config";
import {BASE_API_URL} from "../../api/axios-base";
import {openRouteInNewTab} from "@/utils/navigation";

export default {
  name: "BuyoutList",
  props: {
    goldsmith: {type: Boolean, required: false, default: false}
  },
  data() {
    return {
      search: '',
      headers: [
        {text: 'BOSS', align: 'start', value: 'exported_to_BOSS'},
        {text: 'Číslo', value: 'number'},
        {text: 'Datum výkupu', value: 'finished_date'},
        {text: 'Prodejna', value: 'shop.description'},
        {text: 'Zákazník', value: 'customer'},
        {text: 'Cena (Kč)', value: 'total_price'},
        {text: 'Komodity', value: 'num_commodities'},
        {text: 'Stav', value: 'type_status.description'},
        {text: '', value: 'actions', sortable: false},
      ],
      filters: false,
      buyouts: [],
      loading: true,
      options: {},
      itemsPerPageOptions: [50, 100, 200],
      defaultPageSize: 100,
      count: 0,
      next: null,
      previous: null,
      statuses: undefined,
      selectedStatuses: undefined,
      dateFrom: '',
      dateTo: '',
      shops: undefined,
      selectedShops: undefined,
      paramString: '',
      buyoutsToBoss: []
    }
  },

  async mounted() {
    if (!this.goldsmith) {
      await fetchVisibleStatuses()
          .then((response) => this.statuses = response.data)
    } else {
      this.statuses = goldsmithStatuses
    }
    await fetchVisibleShops()
        .then((response) => {
          this.shops = response.data
          this.selectedShops = response.data
        })
    this.selectedStatuses = this.statuses
    this.getDataFromApi()
  },

  computed: {
    filtersButtonText() {
      if (this.filters) {
        return 'Skrýt filtry'
      }
      return 'Zobrazit filtry'
    },

    dataLength() {
      return this.count
    },
    statusesParamString() {
      let statuses = []
      this.selectedStatuses.forEach(value => {
        statuses.push(value.identifier)
      })
      return statuses.join(';')
    },
    shopsParamString() {
      let shops = []
      this.selectedShops.forEach(value => {
        shops.push(value.identifier)
      })
      return shops.join(';')
    },
    excelExportUrl() {
      return `${BASE_API_URL}/${URLS.buyoutListExcel()}${this.paramString}`
    }
  },

  methods: {
    getDataFromApi() {
      this.loading = true
      fetchBuyouts({
        page: 1,
        page_size: this.defaultPageSize,
        statuses: this.statusesParamString,
        shops: this.shopsParamString
      }).then(response => {
        this.handleResponse(response)
        this.loading = false
      })
    },
    handleResponse(response) {
      this.buyouts = response.data.results
      this.count = response.data.count
      this.next = response.data.next
      this.previous = response.data.previous
    },
    showDetail(item) {
      openRouteInNewTab(this.$router, 'DetailBuyout', {id: item.id});
    },
    editBuyout(item) {
      openRouteInNewTab(this.$router, 'NewBuyout', {id: item.id});
    },
    deleteItem(item) {
      deleteBuyout(item.id)
          .then(() => this.$router.go())
    },
    formatPrice(value) {
      return formattedPrice(value)
    },
    formatDate(value) {
      return formattedDate(value)
    },
    handleAnotherPage(newVal, oldVal) {
      fetchAnother(newVal.page > oldVal.page ? this.next : this.previous).then((response) => {
        this.handleResponse(response)
      })
    },
    getParams(newVal) {
      const ordering = newVal.sortBy[0]
      const desc = newVal.sortDesc[0]
      return desc ?
          {
            page: 1, page_size: newVal.itemsPerPage, ordering: ordering, desc: 1, statuses: this.statusesParamString,
            search: this.search, date_from: this.dateFrom, date_to: this.dateTo, shops: this.shopsParamString
          } :
          {
            page: 1, page_size: newVal.itemsPerPage, ordering: ordering, statuses: this.statusesParamString,
            search: this.search, date_from: this.dateFrom, date_to: this.dateTo, shops: this.shopsParamString
          }
    },
    handleChange(newVal) {
      const params = this.getParams(newVal)
      fetchBuyouts(params)
          .then((response) => {
            this.handleResponse(response)
          })
    },
    handleSearch() {
      this.options.page = 1
      this.handleChange(this.options)
    },
    disableDatesBeforeDateFrom(val) {
      if (this.dateFrom !== '') {
        return val >= this.dateFrom
      }
      return true
    },
    createParamString() {
      const params = this.getParams(this.options)
      let paramString = ''
      for (const [key, value] of Object.entries(params)) {
        if (value !== undefined) {
          paramString = paramString.concat(paramString.includes('?') ? `&${key}=${value}` : `?${key}=${value}`)
        }
      }
      this.paramString = paramString
    },
    downloadExcel() {
      this.createParamString()
      window.open(this.excelExportUrl, '_blank')
    },
    // GOLDSMITH
    processBuyoutByGoldsmith(item) {
      openRouteInNewTab(this.$router, 'GoldsmithDetail', {id: item.id});
    },

    exportToBossChange(id) {
      let index = this.buyoutsToBoss.findIndex(item => item === id)
      if (index !== -1) {
        this.buyoutsToBoss.splice(index, 1)
      } else {
        this.buyoutsToBoss.push(id)
      }
    },

    exportToBoss() {
      exportBuyoutToBoss(this.buyoutsToBoss)
          .then(() => this.$router.go())
    },


  },
  watch: {
    options: {
      handler(newVal, oldVal) {
        if (newVal.sortBy !== oldVal.sortBy && oldVal.sortBy !== undefined) {
          this.loading = true
          this.handleChange(newVal)
          this.loading = false

        } else if (newVal.page !== oldVal.page && oldVal.page !== undefined) {
          this.loading = true
          this.handleAnotherPage(newVal, oldVal)
          this.loading = false
        } else if (newVal.itemsPerPage !== oldVal.itemsPerPage && oldVal.itemsPerPage !== undefined) {
          this.loading = true
          this.handleChange(newVal)
          this.loading = false
        }
      },
      deep: true
    },
    selectedStatuses: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal && oldVal !== undefined) {
          this.options.page = 1
          this.handleChange(this.options)
        }
      }
    },
    selectedShops: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal && oldVal !== undefined) {
          this.options.page = 1
          this.handleChange(this.options)
        }
      }
    },

    dateFrom: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal && oldVal !== undefined) {
          this.options.page = 1
          this.handleChange(this.options)
        }
      }
    },
    dateTo: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal && oldVal !== undefined) {
          this.options.page = 1
          this.handleChange(this.options)
        }
      }
    },

  },
  components: {
    DeletePopup,
    DatePicker
  }
}


</script>
