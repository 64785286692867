<template>
  <div>
    <v-data-table
        :headers="headers"
        :items="batchItems"
        :loading="loading"
        class="elevation-0"
        :hide-default-footer="true"
        disable-pagination
    >
      <template #[`header.action`]="{ header }">
        <div v-if="header.text === 'Zlomky / Prodej'">
          <span>Zlomky / </span>
          <a class="color--goldTone1 text--underline" @click.prevent="markAll">Prodej [vše]</a>
        </div>
        <div v-else>
          {{ header.text }}
        </div>
      </template>


      <template #[`item.action`]="{ item }">
        <v-radio-group
            v-if="!item.is_fraction"
            v-model="item.action"
            row
            class="mt-0 pt-0"
        >
          <v-radio
              value="99"
          />
          <v-radio
              value="sell"
          />
        </v-radio-group>
      </template>

      <template #[`item.image`]="{item}">

        <DialogImagePreview v-if="item.image" :image="item.image">
          <template #activator="{on}">
            <v-icon v-on="on"> mdi-cloud-download</v-icon>
          </template>
        </DialogImagePreview>


        <v-icon v-else
                @click="openUploadQr(item)"
        >
          mdi-qrcode-scan
        </v-icon>
      </template>

      <template #[`item.delete`]="{ item }" v-if="firstState && !batch.reviewed">
        <DeletePopup class="mx-1" :delete-function="() => handleItemDelete(item)"
                     msg="Opravdu si přejete smazat tento předmět?"/>
      </template>

      <template #[`item.edit`]="{ item }">
        <DialogBatchItemEdit :item="item" @update="reload">
          <template #activator="{on}">
            <v-btn v-on="on" class="primary d-block ml-auto" min-width="150" small style="padding-bottom: 2px;">
              Upravit
            </v-btn>
          </template>
        </DialogBatchItemEdit>
      </template>


      <template #no-data>
        Podmínkám neodpovídají žádná data...
      </template>
      <template #loading>
        Načítání dat...
      </template>
    </v-data-table>
    <div class="d-flex flex-wrap ga-4 mx-auto mt-4 justify-center">
      <div v-if="isComplete">
        <v-btn
            :disabled="batch.reviewed"
            class="ml-auto"
            color="primary"
            min-width="250"
            @click="updateComplete()"
        >
          {{ 'Ukončit várku' }}
        </v-btn>
      </div>
      <div v-else>
        <v-btn
            v-if="!isAllFinished"
            :disabled="!isAllChecked"
            class="ml-auto"
            color="primary"
            min-width="250"
            @click="updateStatus()"
        >
          {{ state === '4' ? 'Uložit' : 'Pokračovat' }}
        </v-btn>

      </div>

      <v-btn
          v-if="!isAllExported && (isAllFinished && !isAllFractions)"
          color="primary"
          @click="openExportToBoss()"
      >
        Založit
      </v-btn>
    </div>

    <v-dialog
        v-model="qrDialog"
        activator="parent"
        width="auto"
    >
      <v-card>
        <qrcode-vue :value="this.qrValue" size="300" level="M"/>
      </v-card>
    </v-dialog>

  </div>

</template>
<script>

import {
  deleteBatchItem
} from "@/components/batch/api";
import QrcodeVue from 'qrcode.vue'
import DeletePopup from "@/components/utils/DeletePopup.vue";
import {reverse} from "@/utils/reverse";
import DialogBatchItemEdit from "@/components/batch/DialogBatchItemEdit.vue";
import DialogImagePreview from "@/components/batch/DialogImagePreview.vue";
import {openRouteInNewTab} from "@/utils/navigation";

export default {
  name: 'BatchReviewList',
  components: {DialogImagePreview, DialogBatchItemEdit, DeletePopup, QrcodeVue},
  props: {
    batchItems: {required: true, type: Array},
    id: {required: true, type: String},
    state: {required: true, type: String},
    isComplete: {required: false, type: Boolean, default: false},
    batch: {
      required: false, type: Object, default: () => {
      }
    }
  },
  data() {
    return {
      expanded: [],
      loading: false,
      selectedItem: null,
      qrDialog: false,
      qrValue: null,
    }
  },
  computed: {
    firstState() {
      return this.state === '1'
    },
    headers() {
      let h = []
      if (!this.isAllFinished) {
        h.push({text: 'Zlomky / Prodej', align: 'start', value: 'action', sortable: false},)
      }
      h.push(
          {text: 'Typ', align: 'start', value: 'type_jewel.description'},
          {text: 'Celková hmotnost (g)', value: 'weight'},
          {text: 'Popís', value: 'description'},
          {text: 'Výkup', value: 'buyout.number'},
          {text: 'Obrázek', value: 'image'},
          {text: '', value: 'delete'},
          {text: '', value: 'edit'},
          {text: '', value: 'sortButton', sortable: false}
      )
      return h
    },
    hasNoItems() {
      return !this.batchItems.length
    },
    isAllChecked() {
      if (this.hasNoItems) {
        return false
      }
      let isChecked = true
      this.batchItems.filter(i => i.type_jewel.identifier !== "99").forEach((item) => {
        if (item.action === undefined) {
          isChecked = false
        }
      })
      return isChecked
    },
    isAllFractions() {
      return !this.batchItems.filter(i => i.type_jewel.identifier !== "99").length;
    },
    isAllFinished() {
      if (this.hasNoItems) {
        return false
      }
      let finished = true
      this.batchItems.forEach((item) => {
        if (!item.finished) {
          finished = false
        }
      })
      return finished
    },
    isAllExported() {
      if (this.hasNoItems) {
        return false
      }
      let is_exported = true
      this.batchItems.forEach((item) => {
        if (!item.is_exported) {
          is_exported = false
        }
      })
      return is_exported
    }
  },
  methods: {
    markAll() {
      this.batchItems.forEach((i) => {
        i.action = !i.action ? "sell" : null
      })
    },
    async reload() {
      this.$emit('reload')
    },
    async handleItemDelete(item) {
      await deleteBatchItem(item.id).then(() => {
        this.reload()
      })
    },
    async openUploadQr(item) {
      this.qrValue = reverse('batch:batch_item_upload_image', [item.id])
      console.log(this.qrValue);
      this.qrDialog = !this.qrDialog
    },
    openImage(item) {
      window.open(item.image, '_blank')
    },
    async updateStatus() {
      this.$emit('updateItem', this.batchItems)
    },
    async updateComplete() {
      this.$emit('updateComplete', this.batchItems)
    },
    async back() {
      this.$router.push({
        name: 'BatchList',
      })
    },
    openExportToBoss() {
      openRouteInNewTab(this.$router, 'BatchSendToBoss', {id: this.id});
    },
  }
}

</script>
